
import { computed, defineComponent, ref } from 'vue'
import EnrolmentRow from './EnrolmentRow.vue'
import { userData } from '@/firebase'
import useEnrolmentRequests from '@/composables/useEnrolmentRequests'
import PaginationBar from '@/components/reusable/BasePaginationBottomBar.vue'
import EnrolmentRowSkeleton from './EnrolmentRowSkeleton.vue'
import SearchBar from '@/components/reusable/BaseSearchBar.vue'
import StatusFilterDropdown from './StatusFilterDropdown.vue'
import BaseSortArrow from '@/components/reusable/BaseSortArrow.vue'

export default defineComponent({
  components: {
    EnrolmentRow,
    PaginationBar,
    EnrolmentRowSkeleton,
    SearchBar,
    StatusFilterDropdown,
    BaseSortArrow,
  },
  setup() {
    const paginationBar = ref<null | typeof PaginationBar>(null)
    const searchBar = ref<null | typeof SearchBar>(null)

    const openStatusDropdown = ref(false)

    const role = userData?.claims?.role

    if (!role) {
      return
    }

    const {
      dataOnPage: data,
      loading,
      error,
      currentPage,
      totalPage,
      totalData,
      nextPage,
      prevPage,
      pageSize,
      currentSearchKeyword,
      currentOrderBy,
    } = useEnrolmentRequests()

    const isSearchMode = computed(() => !!currentSearchKeyword?.value ?? false)

    const onSearch = (keyword: string) => {
      if (typeof currentSearchKeyword !== 'undefined') {
        currentSearchKeyword.value = keyword
      }
    }

    const changeSort = (titleToSort: 'Company' | 'EFINs' | 'Campaigns') => {
      if (isSearchMode.value && currentSearchKeyword && searchBar.value) {
        currentSearchKeyword.value = null
      }
      if (!currentOrderBy || !currentOrderBy.value) return
      switch (titleToSort) {
        case 'Company':
          if (currentOrderBy.value.field === 'companyNameOnNitro') {
            currentOrderBy.value.direction =
              currentOrderBy.value.direction === 'desc' ? 'asc' : 'desc'
          } else {
            currentOrderBy.value = {
              field: 'companyNameOnNitro',
              direction: 'asc',
            }
          }
          break
        case 'EFINs':
          if (currentOrderBy.value.field === 'EFINs') {
            currentOrderBy.value.direction =
              currentOrderBy.value.direction === 'desc' ? 'asc' : 'desc'
          } else {
            currentOrderBy.value = {
              field: 'EFINs',
              direction: 'asc',
            }
          }
          break

        case 'Campaigns':
          if (currentOrderBy.value.field === 'whichCampaigns') {
            currentOrderBy.value.direction =
              currentOrderBy.value.direction === 'desc' ? 'asc' : 'desc'
          } else {
            currentOrderBy.value = {
              field: 'whichCampaigns',
              direction: 'asc',
            }
          }
          break

        default:
          break
      }
    }

    return {
      loading,
      data,
      error,
      totalData,
      pageSize,
      currentPage,
      totalPage,
      nextPage,
      prevPage,
      onSearch,
      isSearchMode,
      currentSearchKeyword,
      changeSort,
      currentOrderBy,
      paginationBar,
      openStatusDropdown,
      searchBar,
      role,
    }
  },
})
