
// import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'
import { defineComponent, reactive, toRefs } from 'vue'
import { debounce } from 'lodash'

// type SelectOption = {
//   title: string
//   value: keyof EnrolmentRequestFirestoreDoc
// }

type State = {
  searchValue: string
  // selected: keyof EnrolmentRequestFirestoreDoc
  // selectOptions: SelectOption[]
}

export default defineComponent({
  emits: ['change'],
  props: {
    placeholderText: {
      type: String,
      default: 'Search anything ...',
    },
  },
  setup(_, context) {
    const state = reactive<State>({
      searchValue: '',
    })

    const debounceInputChange = debounce(e => {
      const value = e.target.value
      context.emit('change', value)
    }, 700)
    return {
      ...toRefs(state),
      debounceInputChange,
    }
  },
})
