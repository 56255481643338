
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    direction: {
      type: String as PropType<'asc' | 'desc'>,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
