
import { defineComponent, PropType } from 'vue'
import BaseModal from '../../reusable/BaseModal.vue'

import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'

export default defineComponent({
  components: { BaseModal },
  emits: ['close', 'confirm'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    enrolmentData: {
      type: Object as PropType<EnrolmentRequestFirestoreDoc>,
      required: true,
    },
  },
})
