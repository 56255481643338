<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
  <div
    class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
  >
    <div v-if="blindMode" class="flex-1 flex justify-between">
      <a
        class="cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
        @click="goPrevPage"
      >
        Previous
      </a>
      <a
        class="cursor-pointer ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
        @click="goNextPage"
      >
        Next
      </a>
    </div>
    <div v-else class="flex-1 flex items-center justify-between">
      <div>
        <p class="text-sm leading-5 text-gray-700">
          Showing
          <span class="font-medium">{{ firstRowIndex }}</span>
          to
          <span class="font-medium">{{ lastRowIndex }}</span>
          of
          <span class="font-medium">{{ totalData }}</span>
          results
        </p>
      </div>
      <div>
        <nav class="relative inline-flex shadow-sm">
          <a
            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10  focus:border-blue-300 active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 cursor-pointer"
            aria-label="Previous"
            @click="goPrevPage"
          >
            <!-- Heroicon name: chevron-left -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <span
            class="-ml-px relative inline-flex items-center px-4 py-2 border text-xs text-gray-600 border-gray-300 bg-white leading-5 unselectable"
          >
            {{ currentPage }}
            /
            {{ totalPage }}
          </span>
          <a
            class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:border-blue-300 active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 cursor-pointer"
            aria-label="Next"
            @click="goNextPage"
          >
            <!-- Heroicon name: chevron-right -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  emits: ['next', 'prev'],
  props: {
    totalData: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalPage: {
      type: Number,
      required: true,
    },
    blindMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const firstRowIndex = computed(() => {
      if (props.totalData === 0) {
        return 0
      }
      return props.currentPage * props.pageSize - props.pageSize + 1
    })
    const lastRowIndex = computed(() => {
      if (props.totalData === 0) {
        return 0
      }
      if (props.currentPage === props.totalPage) {
        return props.totalData
      } else {
        return props.currentPage * props.pageSize
      }
    })

    const goNextPage = () => {
      context.emit('next')
    }
    const goPrevPage = () => {
      context.emit('prev')
    }

    return {
      firstRowIndex,
      lastRowIndex,
      goNextPage,
      goPrevPage,
    }
  },
})
</script>

<style scoped>
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
