<template>
  <div class="flex justify-end px-6">
    <div class="w-1/2 mt-1 relative rounded-md shadow-sm">
      <div
        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
      >
        <svg
          class="h-5 w-5 text-gray-500 stroke-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="{2}"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>
      <input
        class="form-input block w-full pl-10 pr-12 sm:text-sm sm:leading-5"
        :placeholder="placeholderText"
        @input="debounceInputChange"
      />
      <!-- <div class="absolute inset-y-0 right-0 flex items-center border-l">
        <select
          v-model="selected"
          aria-label="Currency"
          class="form-select h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm sm:leading-5"
        >
          <option
            v-for="item in selectOptions"
            :key="item.title"
            :value="item.value"
            >{{ item.title }}</option
          >
        </select>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
// import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'
import { defineComponent, reactive, toRefs } from 'vue'
import { debounce } from 'lodash'

// type SelectOption = {
//   title: string
//   value: keyof EnrolmentRequestFirestoreDoc
// }

type State = {
  searchValue: string
  // selected: keyof EnrolmentRequestFirestoreDoc
  // selectOptions: SelectOption[]
}

export default defineComponent({
  emits: ['change'],
  props: {
    placeholderText: {
      type: String,
      default: 'Search anything ...',
    },
  },
  setup(_, context) {
    const state = reactive<State>({
      searchValue: '',
    })

    const debounceInputChange = debounce(e => {
      const value = e.target.value
      context.emit('change', value)
    }, 700)
    return {
      ...toRefs(state),
      debounceInputChange,
    }
  },
})
</script>

<style scoped></style>
