import { FirestoreColNames } from '@/enums/FirestoreColNames'
import { auth, db, FieldValue } from '@/firebase'
import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'
import { computed, ref, watch } from 'vue'
import { userData } from '@/firebase'
import arraySort from 'array-sort'

export type EnrolmentStatus =
  | 'completed'
  | 'pendingTaxNitro'
  | 'pendingRightWay'
  | 'rejected'

const role = userData?.claims?.role

const enrolmentRequests = ref<EnrolmentRequestFirestoreDoc[]>([])
const loading = ref(true)
const error = ref('')

const currentSearchKeyword = ref<string | null>(null)
const currentFilters = ref<EnrolmentStatus[]>([
  'pendingRightWay',
  'pendingTaxNitro',
])
const currentOrderBy = ref({ field: 'createdAt', direction: 'desc' })
const pageSize = ref(8)

const filteredData = ref<EnrolmentRequestFirestoreDoc[]>([])
const totalData = computed(() => filteredData.value.length)
const dataOnPage = ref<EnrolmentRequestFirestoreDoc[]>([])
const currentPage = ref(1)
const totalPage = computed(() =>
  Math.ceil(filteredData.value.length / pageSize.value)
)

db.collection(FirestoreColNames.ENROLMENT_REQUESTS).onSnapshot(function(
  snapshot
) {
  const receivedEnrolments: EnrolmentRequestFirestoreDoc[] = []
  snapshot.forEach(doc => {
    const data = doc.data() as EnrolmentRequestFirestoreDoc

    receivedEnrolments.push(data)
  })
  enrolmentRequests.value = receivedEnrolments
  loading.value = false
})

if (!role) {
  error.value = 'Unauthorized to view Enrollment Requests.'
}

const filterAndSortData = () => {
  loading.value = true
  currentPage.value = 1
  let result: EnrolmentRequestFirestoreDoc[] = enrolmentRequests.value

  if (currentSearchKeyword.value && currentSearchKeyword.value !== '') {
    const searchTerm = currentSearchKeyword.value
    result = result.filter(
      enrolment => enrolment.keywords && enrolment.keywords.includes(searchTerm)
    )
  }

  // Filter deleted - for now deleted Enrolment Req will not show up on Dashboard
  result = result.filter(enrolment => !enrolment.deleted)

  if (currentFilters.value && currentFilters.value.length) {
    const filters = currentFilters.value

    const filteredEnrolments: EnrolmentRequestFirestoreDoc[] = []

    result.forEach(enrolment => {
      // Skip for already in filteredEnrolments
      if (
        filteredEnrolments.find(
          el => el.submissionID === enrolment.submissionID
        )
      )
        return

      if (
        filters.includes('completed') &&
        enrolment.completed &&
        !enrolment.rejected
      ) {
        filteredEnrolments.push(enrolment)
      }

      if (
        filters.includes('pendingTaxNitro') &&
        !enrolment.taxNitroReviewed &&
        !enrolment.rejected
      ) {
        filteredEnrolments.push(enrolment)
      }

      if (
        filters.includes('pendingRightWay') &&
        !enrolment.rightWayReviewed &&
        enrolment.taxNitroReviewed &&
        !enrolment.rejected
      ) {
        filteredEnrolments.push(enrolment)
      }

      if (filters.includes('rejected') && enrolment.rejected) {
        filteredEnrolments.push(enrolment)
      }
    })

    result = filteredEnrolments
  }

  if (currentOrderBy.value) {
    const field = currentOrderBy.value.field
    const direction = currentOrderBy.value.direction

    result = arraySort(result, field, { reverse: direction === 'desc' })
  }

  loading.value = false
  return result
}

const paginateData = () => {
  dataOnPage.value = filteredData.value.slice(
    (currentPage.value - 1) * pageSize.value,
    currentPage.value * pageSize.value
  )
}

watch(
  [enrolmentRequests, currentSearchKeyword],
  () => {
    if (loading.value) return
    filteredData.value = filterAndSortData()
  },
  { immediate: false, deep: true }
)

watch(
  [currentFilters, currentOrderBy],
  () => {
    if (loading.value) return
    filteredData.value = filterAndSortData()
  },
  { immediate: true, deep: true }
)

watch(
  [filteredData, currentPage],
  () => {
    paginateData()
  },
  { immediate: false, deep: true }
)

const nextPage = () => {
  if (currentPage.value === totalPage.value) return
  currentPage.value++
}
const prevPage = () => {
  if (currentPage.value === 1) return
  currentPage.value--
}

export default function() {
  return {
    dataOnPage,
    loading,
    totalData,
    totalPage,
    nextPage,
    prevPage,
    currentPage,
    enrolmentRequests,
    pageSize,
    error,
    currentFilters,
    currentSearchKeyword,
    currentOrderBy,
  }
}

type RejectEnrolmentRequestPayload = {
  enrolmentData: EnrolmentRequestFirestoreDoc
  team: 'taxnitro' | 'rightway'
  reason: string
}

export async function rejectEnrolmentRequest(
  payload: RejectEnrolmentRequestPayload
) {
  const { enrolmentData, team, reason } = payload

  if (!enrolmentData || !enrolmentData.submissionID)
    return Promise.reject('Missing Enrolment Request data.')

  const rejectedPayload: Pick<
    EnrolmentRequestFirestoreDoc,
    'rejected' | 'rejectedBy'
  > = {
    rejected: true,
    rejectedBy: {
      reason: reason,
      rejectedAt: FieldValue.serverTimestamp(),
      rejecterName: auth.currentUser?.displayName || 'Unknown',
      rejecterUID: auth.currentUser?.uid ?? 'Unknown',
      rejecterTeam: team,
    },
  }

  await db
    .collection(FirestoreColNames.ENROLMENT_REQUESTS)
    .doc(enrolmentData.submissionID)
    .set(rejectedPayload, { merge: true })
}

type ApproveEnrolmentRequestPayload = {
  enrolmentData: EnrolmentRequestFirestoreDoc
  team: 'taxnitro' | 'rightway'
  apiKey?: string
  locationID?: string
}

export async function approveEnrolmentRequest(
  payload: ApproveEnrolmentRequestPayload
) {
  const { enrolmentData, team, apiKey, locationID } = payload

  if (!enrolmentData) return Promise.reject('Enrolment request not found.')

  const isBothReviewed =
    enrolmentData.taxNitroReviewed && enrolmentData.rightWayReviewed

  if (isBothReviewed)
    return Promise.reject(
      'Enrolment request had been reviewed by both parties.'
    )
  if (!enrolmentData.submissionID)
    return Promise.reject('Please provide submission ID for approval.')

  try {
    // For TaxNitro as reviewer
    if (team === 'taxnitro') {
      if (enrolmentData.taxNitroReviewed)
        return Promise.reject('This enrolment has been reviewed.')

      if (!apiKey || !locationID)
        return Promise.reject('Empty locationID or apiKey.')

      // Set as reviewed in firestore
      await db
        .collection(FirestoreColNames.ENROLMENT_REQUESTS)
        .doc(enrolmentData.submissionID)
        .update({
          taxNitroReviewed: true,
          taxNitroReviewer: {
            reviewedAt: FieldValue.serverTimestamp(),
            reviewerUID: auth.currentUser?.uid ?? 'Unknown',
            reviewerName: auth.currentUser?.displayName || 'Unknown',
          },
          locationID: locationID,
          HLApiKey: apiKey,
        })

      return
    } else if (team === 'rightway') {
      if (enrolmentData.rightWayReviewed)
        return Promise.reject('This enrolment has been reviewed')

      // Set as reviewed in firestore
      await db
        .collection(FirestoreColNames.ENROLMENT_REQUESTS)
        .doc(enrolmentData.submissionID)
        .update({
          rightWayReviewed: true,
          rightWayReviewer: {
            reviewedAt: FieldValue.serverTimestamp(),
            reviewerUID: auth.currentUser?.uid ?? 'Unknown',
            reviewerName: auth.currentUser?.displayName || 'Unknown',
          },
        })

      return
    }

    Promise.reject('Unknown reviewer team.')
  } catch (err) {
    throw new Error(err)
  }
}
