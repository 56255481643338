
import { computed, defineComponent } from 'vue'

export default defineComponent({
  emits: ['next', 'prev'],
  props: {
    totalData: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalPage: {
      type: Number,
      required: true,
    },
    blindMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const firstRowIndex = computed(() => {
      if (props.totalData === 0) {
        return 0
      }
      return props.currentPage * props.pageSize - props.pageSize + 1
    })
    const lastRowIndex = computed(() => {
      if (props.totalData === 0) {
        return 0
      }
      if (props.currentPage === props.totalPage) {
        return props.totalData
      } else {
        return props.currentPage * props.pageSize
      }
    })

    const goNextPage = () => {
      context.emit('next')
    }
    const goPrevPage = () => {
      context.emit('prev')
    }

    return {
      firstRowIndex,
      lastRowIndex,
      goNextPage,
      goPrevPage,
    }
  },
})
