
import useEnrolmentRequests, {
  EnrolmentStatus,
} from '@/composables/useEnrolmentRequests'
import { defineComponent, reactive, toRefs } from 'vue'

type State = {
  options: Array<{
    title: string
    value: EnrolmentStatus
  }>
}

export default defineComponent({
  setup() {
    const state = reactive<State>({
      options: [
        {
          title: 'Pending TaxNitro',
          value: 'pendingTaxNitro',
        },
        {
          title: 'Pending RightWay',
          value: 'pendingRightWay',
        },
        {
          title: 'Completed',
          value: 'completed',
        },
        {
          title: 'Rejected',
          value: 'rejected',
        },
      ],
    })
    const { currentFilters } = useEnrolmentRequests()

    const toggleOption = (value: EnrolmentStatus) => {
      if (currentFilters.value.includes(value)) {
        currentFilters.value = currentFilters.value.filter(
          item => item !== value
        )
      } else {
        currentFilters.value.push(value)
      }
    }
    return {
      ...toRefs(state),
      toggleOption,
      currentFilters,
    }
  },
})
