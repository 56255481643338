
import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'
import { defineComponent, PropType } from 'vue'
import { RouteName } from '@/router/RouteName'
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import { auth, db, FieldValue, userData } from '@/firebase'
import DeleteEnrolmentModal from './DeleteEnrolmentModal.vue'
import { FirestoreColNames } from '@/enums/FirestoreColNames'

export default defineComponent({
  components: { DeleteIcon, DeleteEnrolmentModal },
  props: {
    item: {
      type: Object as PropType<EnrolmentRequestFirestoreDoc>,
      required: true,
    },
  },
  data() {
    return {
      RouteName: RouteName,
      isDeleting: false,
      showDeleteModal: false,
    }
  },
  computed: {
    isAdmin(): boolean {
      return userData?.claims?.role === 'admin' ?? false
    },
  },
  methods: {
    async onDeleteConfirm(submissionID: string) {
      // Handle delete enrolment in database
      const deleteEnrolmentPayload: Pick<
        EnrolmentRequestFirestoreDoc,
        'deleted' | 'deletedBy'
      > = {
        deleted: true,
        deletedBy: {
          deletedAt: FieldValue.serverTimestamp(),
          deleterUID: auth.currentUser?.uid ?? 'Unknown',
          deleterName: auth.currentUser?.displayName ?? 'Unknown',
        },
      }

      await db
        .collection(FirestoreColNames.ENROLMENT_REQUESTS)
        .doc(submissionID)
        .set(deleteEnrolmentPayload, {
          merge: true,
        })

      this.showDeleteModal = false
    },
  },
})
