<template>
  <tr>
    <td class="px-6 py-4 whitespace-no-wrap">
      <div class="flex flex-col items-start justify-items-center">
        <div class="text-sm leading-5 font-medium text-gray-900">
          {{ item.companyNameOnNitro }}
        </div>
        <div class="text-sm leading-5 text-gray-500">
          {{ item.emailOnNitro }}
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-no-wrap">
      <div class="text-sm leading-5 text-gray-900">
        <p class="text-xs font-semibold">
          {{ item.EFINs.join(', ') }}
        </p>
      </div>
    </td>
    <td class="px-6 py-4 w-64">
      <p class="text-xs">
        {{ item.whichCampaigns.join(', ') }}
      </p>
    </td>
    <td
      class=" px-3 py-4 text-sm text-center leading-3 text-gray-500 flex justify-items-start items-center"
    >
      <span
        v-if="item.rejected"
        class="px-2 mx-1 mt-1 py-2 text-xs font-semibold rounded-lg bg-red-300 text-red-900"
      >
        Rejected
      </span>
      <span
        v-else-if="item.taxNitroReviewed && item.rightWayReviewed"
        class="px-2 mt-1 mx-1 py-2 text-xs font-semibold rounded-lg bg-green-400 text-green-800"
      >
        Completed
      </span>
      <span
        v-else-if="!item.taxNitroReviewed"
        class="px-2 mt-1 mx-1 py-2 text-xs font-semibold rounded-lg bg-brand-light text-brand-dark"
      >
        Pending TaxNitro
      </span>
      <span
        v-else-if="!item.rightWayReviewed && item.taxNitroReviewed"
        class="px-2 mt-1 mx-1 py-2 text-xs font-semibold rounded-lg bg-blue-300 text-blue-800"
      >
        Pending RightWay
      </span>
      <span
        v-else
        class="px-2 mx-1 mt-1 py-2 text-xs font-semibold rounded-lg bg-yellow-300 text-yellow-800"
      >
        Pending
      </span>
    </td>
    <td
      class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"
    >
      <router-link
        :to="{
          name: RouteName.ENROLMENT_REVIEW,
          params: { id: item.submissionID },
        }"
        class="text-indigo-600 hover:text-indigo-900"
        >Review</router-link
      >
      <DeleteIcon
        v-if="isAdmin"
        class="h-6 w-6 text-gray-500 hover:text-red-600 cursor-pointer ml-6 inline-block"
        @click="showDeleteModal = true"
      />
    </td>
    <delete-enrolment-modal
      :loading="isDeleting"
      :show="showDeleteModal"
      @close="showDeleteModal = false"
      :enrolmentData="item"
      @confirm="onDeleteConfirm"
    />
  </tr>
</template>

<script lang="ts">
import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'
import { defineComponent, PropType } from 'vue'
import { RouteName } from '@/router/RouteName'
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import { auth, db, FieldValue, userData } from '@/firebase'
import DeleteEnrolmentModal from './DeleteEnrolmentModal.vue'
import { FirestoreColNames } from '@/enums/FirestoreColNames'

export default defineComponent({
  components: { DeleteIcon, DeleteEnrolmentModal },
  props: {
    item: {
      type: Object as PropType<EnrolmentRequestFirestoreDoc>,
      required: true,
    },
  },
  data() {
    return {
      RouteName: RouteName,
      isDeleting: false,
      showDeleteModal: false,
    }
  },
  computed: {
    isAdmin(): boolean {
      return userData?.claims?.role === 'admin' ?? false
    },
  },
  methods: {
    async onDeleteConfirm(submissionID: string) {
      // Handle delete enrolment in database
      const deleteEnrolmentPayload: Pick<
        EnrolmentRequestFirestoreDoc,
        'deleted' | 'deletedBy'
      > = {
        deleted: true,
        deletedBy: {
          deletedAt: FieldValue.serverTimestamp(),
          deleterUID: auth.currentUser?.uid ?? 'Unknown',
          deleterName: auth.currentUser?.displayName ?? 'Unknown',
        },
      }

      await db
        .collection(FirestoreColNames.ENROLMENT_REQUESTS)
        .doc(submissionID)
        .set(deleteEnrolmentPayload, {
          merge: true,
        })

      this.showDeleteModal = false
    },
  },
})
</script>

<style scoped></style>
